import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import Amplify, { Auth }  from 'aws-amplify';
import config from '../aws-exports';
// import 'bootstrap/dist/css/bootstrap.min.css';
Amplify.configure(config);
Auth.configure(config);

const IndexPage = () => (
  <>
    <App />
  </>
)

export default IndexPage
